<template>
  <div class="h-full flex flex-col">
      <loader :loading="this.loading" :backdrop="true" />
      <p class="text-center title text-gray-400">setup wizard</p>
      <p class="text-center title">welcome to quext digital human!</p>
      <div>
        <div class="w-4/5 self-center m-auto">
          <div class="flex space-x-4 py-16">
            <div class="flex-1 text-center text-gray-400 text-sm">
              <icon name="property" class="h-32 w-32 inline mb-4"></icon>
              <p>community information</p>
            </div>
            <div class="flex-1 text-center text-gray-400 text-sm">
              <icon name="pool" class="h-32 w-32 inline mb-4"></icon>
              <p>community amenities</p>
            </div>
            <div class="flex-1 text-center text-gray-400 text-sm">
              <icon name="bed" class="h-32 w-32 inline mb-4"></icon>
              <p>unit amenities</p>
            </div>
            <div class="flex-1 text-center text-gray-400 text-sm">
              <icon name="money" class="h-32 w-32 inline mb-4 text-red-600"></icon>
              <p>community fees</p>
            </div>
          </div>
          <p class="text-center">
            in this quick wizard, you'll complete 4 essential steps that will enable your digital leasing agent to begin holding conversations about your specific amenities. it should take about 10 minutes. once complete, you'll probably still want to get in there, upload photos, and create some custom responses, but you can take your time with that!
          </p>
        </div>
        <div class="es-message font-frank mt-8 text-xl font-700 mx-auto">
            <span @click="() => handleSetupRedirect()">get started</span>
        </div>
      </div>
      <modal-footer :footer="footer" :tertiary="cancel"/>
  </div>
  
</template>

<script>
import AuthMixin from '@/components/auth/AuthMixin'
import ModalNavigation from '@/mixins/ModalNavigation'
import ModalFooter from '@/components/ui/modals/ModalFooter'
import NotifyMixin from '@/mixins/NotifyMixin'
import Icon from "@/components/ui/Icon";
import Loader from '@/components/ui/Loader'

export default {
  mixins: [AuthMixin, ModalNavigation, NotifyMixin, ],
  components: { Loader, Icon, ModalFooter },
  data() {
    return { 
      loading: false,
      footer: {
        tertiaryButton: 'cancel',
      }, 
    }
  },
  methods: {
    handleSetupRedirect() {
      this.loading = true
      this.$dhDataProvider
        .create('communityConversationById', { communityId: this.community?.id })
        .then(() => {
          this.$router.push({ name: 'setup-wizard.setup' }).then( () => {});
        })
        .catch((error) => {
          this.notifyError(error.message)
        })
        .finally(() => this.loading = false)
    },
    cancel() {
     this.$router.push({ name: 'bookmarks' });
    },
  }
}
</script>

<style scoped>
.es-message{
    text-align: center;
    max-width: 50ch;
}
.es-message span{
    @apply block text-blue-500 border border-blue-500 py-4 px-6 mt-6 mx-auto text-lg;
    width: fit-content;
}
.es-message span:hover{
    background: #3598DB;
    color: #fff;
    cursor:pointer;
}
</style>
